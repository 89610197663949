//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    titleImage: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
    link: {
      type: Boolean,
      default: true,
    },
    hasTitleInSubMenu: {
      type: Boolean,
      default: true,
    },
    img: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    mobileExtraMenu: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hideTimer: null,
      showTimer: null,
    }
  },
  computed: {
    ...mapGetters('ui', [
      'isSidebarActive',
      'shouldClearHiderTimer',
      'darkMode',
    ]),
    name() {
      return this.to.split('/').pop()
    },

    active() {
      return this.$store.state.ui.navbar === this.name
    },
  },
  watch: {
    async shouldClearHiderTimer(val) {
      if (val && this.active && this.hideTimer) {
        await this.onClearHideTimer()
      }
    },

    isSidebarActive(val) {
      if (!val) {
        this.doHideNavbar(this.name)
      }
    },

    $route() {
      this.$nextTick(() => {
        this.doHideNavbar(this.name)
      })
    },
  },
  methods: {
    ...mapActions('ui', [
      'toggleSidebar',
      'doShowNavbar',
      'doHideNavbar',
      'doClearHiderTimer',
    ]),
    openSubmenu(e) {
      if (window.innerWidth > 1023 || !this.$slots.default) {
        this.$router.push(this.localePath(this.to))
        return
      }
      e.preventDefault()
      this.$emit('open')
      this.doShowNavbar(this.name)
      this.$parent.$refs.headerMenu.scrollTop = 0
      return false
    },
    closeSubmenu(e) {
      if (window.innerWidth > 1023) return
      e.preventDefault()

      // if (this.name === 'sales') {
      //   document.getElementsByClassName(
      //     'header-menu-container'
      //   )[0].scrollLeft = 0
      // } else {
      this.doHideNavbar(this.name)
      // }

      return false
    },
    showDesktopSubmenu() {
      if (window.innerWidth < 1024) return
      if (this.active) {
        this.doClearHiderTimer(true)
      }

      if (this.showTimer !== null || this.active) {
        return -1
      }

      this.onClearHideTimer()

      this.$emit('open')
      this.doShowNavbar(this.name)

      setTimeout(() => {
        this.onClearShowTimer()
      }, 600)
    },
    hideDesktopSubmenu() {
      if (window.innerWidth < 1024) return
      if (this.hideTimer !== null) {
        return -1
      }

      this.onClearShowTimer()

      this.hideTimer = setTimeout((e) => {
        this.doHideNavbar(this.name)

        this.onClearHideTimer()
      }, 100)
    },
    onClearHideTimer() {
      if (this.hideTimer) {
        return new Promise((resolve, reject) => {
          this.doClearHiderTimer(false)

          clearTimeout(this.hideTimer)

          this.hideTimer = null

          resolve()
        })
      }
    },
    onClearShowTimer() {
      if (this.showTimer) {
        clearTimeout(this.showTimer)

        this.showTimer = null
      }
    },
    closeOnDesktop(e) {
      if (window.innerWidth < 1024 || !e.target.getAttribute('href')) return

      this.doHideNavbar(this.name)
    },
    closeOnMobile(e) {
      if (window.innerWidth > 1023 || !e.target.getAttribute('href')) return

      this.toggleSidebar(false)
    },
  },
}
