var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group header-menu flex items-center",class:{ 'is-active': _vm.active },attrs:{"id":_vm.id},on:{"mouseleave":_vm.hideDesktopSubmenu}},[(false)?_c('NuxtLink',_vm._g(_vm._b({staticClass:"\n      header-menu-button\n      font-serif\n      italic\n      text-lg\n      flex\n      items-center\n      w-full\n      h-full\n      py-2\n      lg:py-0\n      group-hover:text-black\n      z-0\n    ",class:{
      'has-indicator': _vm.$slots.default,
    },attrs:{"to":_vm.localePath(_vm.to),"rel":"noreferrer"}},'NuxtLink',_vm.$attrs,false),_vm.$listeners),[(_vm.titleImage)?_c('div',[_c('img',{attrs:{"src":_vm.titleImage,"alt":_vm.title}})]):_c('div',[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"\n        block\n        lg:hidden\n        ml-auto\n        text-xs text-gray-800\n        hover:text-black\n        lg:text-center\n      "},[_c('Icon',{attrs:{"set":"far","name":"chevron-right"}})],1):_vm._e()]):_c('button',_vm._g(_vm._b({staticClass:"\n      header-menu-button\n      flex\n      items-center\n      w-full\n      h-full\n      py-2\n      lg:py-0\n      z-0\n    ",class:[
      {
        'has-indicator': _vm.$slots.default,
      },
      _vm.titleClass,
      _vm.mobileExtraMenu ? 'font-lato' : 'font-serif italic text-xl' ],style:(_vm.darkMode && _vm.$i18n.locale === 'en' ? 'color: #eeeeee' : 'color: black'),attrs:{"id":'header-' + _vm.title + '-open-btn'},on:{"click":_vm.openSubmenu,"mouseenter":_vm.showDesktopSubmenu,"mouseleave":_vm.hideDesktopSubmenu}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"lg:text-center flex items-center justfiy-center",class:[
        _vm.className !== '' ? _vm.className : null,
        _vm.flag ? 'text-14px' : null ]},[(_vm.flag)?[_c('div',{staticClass:"country-list-img mr-2"},[(_vm.img)?_c('img',{staticClass:"item-image",attrs:{"src":_vm.img,"alt":_vm.alt}}):_vm._e()])]:[(_vm.img)?_c('img',{staticClass:"item-image",attrs:{"src":_vm.img,"alt":_vm.alt}}):_vm._e()],_vm._v(" "),(_vm.titleImage)?_c('div',[_c('img',{attrs:{"src":_vm.titleImage,"width":"82","height":"26","alt":_vm.title}})]):_c('div',[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])],2),_vm._v(" "),(_vm.$slots.default)?_c('div',{staticClass:"flex lg:hidden ml-auto text-md",class:[
        _vm.className !== '' ? _vm.className : 'text-gray-800 hover:text-black',
        _vm.mobileExtraMenu ? 'text-xl' : null ]},[_c('Icon',{attrs:{"set":"far","name":"chevron-right"}})],1):_vm._e()]),_vm._v(" "),(_vm.$slots.default)?_c('div',{ref:"submenuContainer",staticClass:"\n      header-submenu-container\n      pt-4\n      pb-16\n      lg:pb-8\n      flex flex-col\n      z-10\n      overflow-hidden\n      trans-move\n    ",style:(_vm.darkMode && _vm.$i18n.locale === 'en'
        ? 'background: black;'
        : 'background: white;'),on:{"click":_vm.closeOnMobile,"mouseenter":function($event){return _vm.doClearHiderTimer(true)}}},[_c('div',{staticClass:"\n        lg:hidden\n        flex flex-row\n        items-center\n        flex-shrink-0 flex-grow-0\n        border-b border-gray-300\n        pb-3\n        px-6\n      "},[_c('button',{staticClass:"trans-color flex flex-grow items-center",attrs:{"id":'close-' + _vm.title + '-submenu-btn'},on:{"click":_vm.closeSubmenu}},[_c('Icon',{staticClass:"mr-2 text-sm",attrs:{"set":"far","name":"chevron-left"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('button.back')))])],1),_vm._v(" "),(!_vm.link)?_c('button',{staticClass:"text-xl block lg:hidden font-serif italic",attrs:{"id":'close-' + _vm.title + 'onmobile-btn'},on:{"click":_vm.closeOnMobile}},[_c('span',{domProps:{"textContent":_vm._s(_vm.title)}})]):_c('NuxtLink',{staticClass:"text-xl block lg:hidden font-serif italic",attrs:{"to":_vm.localePath(_vm.to)},nativeOn:{"click":function($event){return _vm.closeOnMobile.apply(null, arguments)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.title)}})])],1),_vm._v(" "),(_vm.hasTitleInSubMenu)?_c('div',{staticClass:"\n        lg:container\n        w-full\n        md:w-unset\n        mx-auto\n        lg:overflow-visible\n        flex-grow\n        py-3\n        px-6\n        lg:p-4\n      ",class:{ 'overflow-auto': _vm.active }},[_vm._t("default")],2):_c('div',{staticClass:"\n        container\n        mx-auto\n        overflow-auto\n        lg:overflow-visible\n        flex-grow\n        p-3\n        lg:p-4\n      "},[_vm._t("default")],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }